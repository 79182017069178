import React, {useContext} from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import color, { themeColors } from "../../styles/colors";
import { screen } from "../../styles/mixins";
import LinkTo from "./LinkTo";
import { darken } from "polished";
import { css } from "@emotion/react";
import { ReactComponent as Chevron } from "../../images/vectors/chevron.svg";
import { button, buttonSmall, h6 } from "../../styles/typography";
import { TrackingIDContext  } from '../TrackingIds';
import { useCookies } from 'react-cookie';

const buttonBase = css`
	display: inline-block;
	border-radius: 5rem;
	padding: 1.04rem 1.75rem;
	text-decoration: none;
	transition: all 0.2s;
	text-align: center;
	${button};

	@media ${screen("md")} {
		padding: 1.04rem 1.75rem;
	}

	@media ${screen("ms")} {
		padding-left: 2.2rem;
		padding-right: 2.2rem;
	}

	svg {
		transition: transform 0.2s;

		path {
			transition: stroke 0.2s;
		}
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		cursor: default;
	}
`;

const basic = (props) => css`
	color: ${themeColors[props.colorTheme].accent1};
	padding: 0;

	svg {
		height: 0.9em;
		margin-left: 0.5em;
		display: inline-block;
		vertical-align: middle;
	}

	@media ${screen("md")} {
		padding: 0;
	}

	@media ${screen("ms")} {
		padding: 0;
	}

	&:hover:not(:disabled) {
		color: ${darken(0.1, themeColors[props.colorTheme].accent1)};

		svg {
			transform: translateX(0.4em);

			path {
				stroke: ${darken(0.1, themeColors[props.colorTheme].accent1)};
			}
		}
	}
`;

const standard = (props) => css`
	background-color: ${color("orange")};
	color: ${color("white")};
	text-transform: uppercase;
	${buttonSmall};

	&:hover:not(:disabled) {
		background-color: ${darken(0.1, color("orange"))};
	}
`;

const secondary = (props) => css`
	background-color: ${color("light-grey")};
	color: ${color("white")};
	text-transform: uppercase;
	${buttonSmall};

	&:hover:not(:disabled) {
		background-color: ${darken(0.1, color("light-grey"))};
	}
`;

const white = (props) => css`
	background-color: ${color("white")};
	color: ${color("orange")};
	text-transform: uppercase;
	${buttonSmall};

	&:hover:not(:disabled) {
		background-color: ${darken(0.03, color("white"))};
	}
`;

const subNav = (props) => css`
	background: none;
	color: ${themeColors[props.colorTheme].accent1};
	padding: 1.07rem 1.75rem;

	@media ${screen("md")} {
		${h6}
		padding: 0.94rem 2rem;
	}

	@media ${screen("ms")} {
		padding-left: 2.2rem;
		padding-right: 2.2rem;
	}

	&:hover:not(:disabled),
	&.active {
		background-color: ${props.colorTheme === "light"
			? color("purple")
			: color("light-grey")};
	}
`;

const outline = (props) => css`
	color: ${themeColors[props.colorTheme].accent1};

	svg {
		height: 0.9em;
		margin-left: 0.5em;
		display: inline-block;
		vertical-align: middle;
	}

	&:hover:not(:disabled) {
		color: ${darken(0.1, themeColors[props.colorTheme].accent1)};

		svg {
			transform: translateX(0.4em);

			path {
				stroke: ${darken(0.1, themeColors[props.colorTheme].accent1)};
			}
		}
	}

	@media ${screen("md")} {
		border: 1px solid ${color("orange")};
		color: ${color("orange")};
		background-color: ${color("white")};

		svg {
			display: none;
		}

		&:hover:not(:disabled) {
			background-color: ${darken(0.1, color("orange"))};
			border-color: ${darken(0.1, color("orange"))};
			color: ${color("white")};
		}

		&::after {
			content: "";
			display: none;
		}
	}
`;

const small = (props) => css`
	padding: 1.25em 2em;

	@media ${screen("md")} {
		padding: 0.75em 2em;
	}
`

const ButtonEl = styled.button`
	${buttonBase};
	${(props) => {
		switch (props.size) {
			case "small":
				return small;
			default:
				return null;
		}
	}};
	${(props) => {
		switch (props.type) {
			case "basic":
				return basic;
			case "secondary":
				return secondary;
			case "white":
				return white;
			case "outline":
				return outline;
			case "sub-nav":
				return subNav;
			default:
				return standard;
		}
	}};

`;

const NoWrap = styled.span`
	white-space: nowrap;
`;

const Button = ({
	children,
	className,
	colorTheme = "light",
	url,
	type = "standard",
	disabled,
	size = "regular",
	...rest
}) => {
	const trackingValues = useContext(TrackingIDContext);
	const [cookies] = useCookies(['_fbc', '_fbp', ]);
	const fbc = cookies._fbc ? `&fbc=${cookies._fbc}` : ``;
	const fbp = cookies._fbp ? `&fbp=${cookies._fbp}` : ``;
	const { gclid, fbclid } = trackingValues ? trackingValues : {};

	if(url && url.includes('apply.thelendingpeople.co.nz') && (fbclid || gclid || fbc || fbp)){
		url = `${url}?${gclid}${fbclid}${fbc}${fbp}`
	}

	return (
		<ButtonEl
			url={url}
			as={url ? LinkTo : "button"}
			className={className}
			colorTheme={colorTheme}
			type={type}
			size={size}
			disabled={disabled}
			{...rest}>
			{children}
			{(type === "basic" || type === "outline") && (
				<NoWrap>
					&#65279;
					<Chevron />
				</NoWrap>
			)}
		</ButtonEl>
	);
};

Button.propTypes = {
	colorTheme: PropTypes.string,
	url: PropTypes.string,
	type: PropTypes.string,
};

Button.defaultProps = {
	colorTheme: "light",
	type: "standard",
};

export default Button;
