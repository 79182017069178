import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const LinkTo = ({
	url,
	external,
	children,
	className,
	target,
	fake,
	linkType,
	element,
	onClick,
	colorTheme,
	isHeading,
	...rest
}) => {
	let type = external || target === "_blank" ? "external" : "internal";

	if (fake || url === "") type = "fake";

	if (linkType === "entry" && element) {
		url = element.uri === "__home__" ? `/` : `/${element.uri}`;

		if (element?.slug === "personal-loans") {
			// we just need the slug for personal-loans page and full uri for the sub pages
			url = `/${element?.slug}`;
		}
	}

	const link = () => {
		switch (type) {
			case "external":
				return (
					<a
						href={url}
						target={target ? "_blank" : null}
						rel="nofollow noreferrer"
						className={className}
						onClick={onClick}
						{...rest}>
						{children}
					</a>
				);
			case "fake":
				return <span className={className}>{children}</span>;
			default:
				return (
					<Link to={url} className={className} onClick={onClick} {...rest}>
						{children}
					</Link>
				);
		}
	};

	return url ? link() : null;
};

LinkTo.propTypes = {
	url: PropTypes.string,
	external: PropTypes.bool,
	target: PropTypes.string,
	fake: PropTypes.bool,
	onClick: PropTypes.func,
};

LinkTo.defaultProps = {};

export default LinkTo;
