import React, { useEffect, useMemo, useState } from "react";
import Image from "gatsby-image";

const FluidImagerX = ({
	className,
	image,
	sizes,
	media,
	artDirection,
	ratio,
	gatsbyImage = true,
	imgStyle,
	...rest
}) => {
	const [srcArtDirectedImage, setSrcArtDirectedImage] = useState(null);

	const fluid = useMemo(() => {
		if (!image) return;

		const fluidProps = [generateFluidProp(image, sizes, ratio, null, media)];
		if (!artDirection) return fluidProps;

		Object.keys(artDirection).forEach((currentMedia) => {
			const {
				image,
				sizes = null,
				ratio = null,
				key = null,
				media,
			} = artDirection[currentMedia];
			fluidProps.push({
				...generateFluidProp(image, sizes, ratio, key, media),
				media: currentMedia,
			});
		});

		return fluidProps;
	}, [image, sizes, artDirection]);

	useEffect(() => {
		if (!gatsbyImage) {
			const updateSrcArtDirectedImage = () =>
				setSrcArtDirectedImage(getCurrentSrcData(fluid));
			updateSrcArtDirectedImage();

			window.addEventListener("resize", updateSrcArtDirectedImage);

			return () => {
				window.removeEventListener("resize", updateSrcArtDirectedImage);
			};
		}
	}, [setSrcArtDirectedImage, getCurrentSrcData, fluid]);

	const srcImage = srcArtDirectedImage ?? fluid[0];

	const imageEl = gatsbyImage ? (
		<Image className={className} fluid={fluid} imgStyle={imgStyle} {...rest} />
	) : (
		<div
			className={className}
			style={{ position: `relative`, overflow: `hidden` }}>
			<div
				style={{
					width: `100%`,
					paddingBottom: `${100 / srcImage.aspectRatio ?? 100}%`,
				}}></div>
			<picture>
				{generateImageSources(groupByMedia([].concat(fluid)))}
				<img
					sizes={srcImage.sizes}
					src={srcImage.src}
					srcSet={srcImage.srcSet}
					{...rest}
					style={{
						position: `absolute`,
						top: 0,
						left: 0,
						width: `100%`,
						height: `100%`,
						objectFit: `cover`,
						objectPosition: `center`,
						...imgStyle,
					}}
				/>
			</picture>
		</div>
	);

	return fluid && imageEl;
};

export default FluidImagerX;

export const generateFluidProp = (
	image,
	sizes = "100vw",
	ratio = null,
	key = null,
	media = undefined
) => {
	if (!image) return null;

	const {
		imagerX,
		imagerXWp,
		placeholder = null,
		url: sourceUrl,
		width: sourceWidth,
		height: sourceHeight,
	} = image;
	const isGif = sourceUrl.indexOf(".gif") > 0;

	// adding 'key' query string to avoid 'duplicate key' warning on Gatsby Image
	const src = !isGif && imagerX ? imagerX[imagerX.length - 1].url : sourceUrl;
	const concatenator = src.includes("?") ? "&" : "?";
	const keyQueryString = key && `${concatenator}key=${key}`;
	const srcUrlWithKey = `${src}${keyQueryString ?? ""}`;

	return {
		aspectRatio: ratio ? ratio : sourceWidth / sourceHeight,
		sizes: sizes,
		base64: placeholder,
		src: srcUrlWithKey,
		media: media,
		srcSet: !isGif && imagerX
			? imagerX
					.map((image) => {
						return `${image.url} ${image.width}w`;
					})
					.join(",")
			: "",

		srcWebp: imagerXWp && !isGif ? imagerXWp[imagerXWp.length - 1].url : null,
		srcSetWebp:
			imagerXWp && !isGif
				? imagerXWp
						.map((image) => {
							return `${image.url} ${image.width}w`;
						})
						.join(",")
				: null,
	};
};

// functions from Gatsby Image source code:

const isBrowser = typeof window !== `undefined`;

function generateImageSources(imageVariants) {
	return imageVariants.map(({ src, srcSet, srcSetWebp, media, sizes }) => (
		<React.Fragment key={src}>
			{srcSetWebp && (
				<source
					type="image/webp"
					media={media}
					srcSet={srcSetWebp}
					sizes={sizes}
				/>
			)}
			{srcSet && <source media={media} srcSet={srcSet} sizes={sizes} />}
		</React.Fragment>
	));
}

// Return an array ordered by elements having a media prop, does not use
// native sort, as a stable sort is not guaranteed by all browsers/versions
function groupByMedia(imageVariants) {
	const withMedia = [];
	const without = [];
	imageVariants.forEach((variant) =>
		(variant.media ? withMedia : without).push(variant)
	);

	if (without.length > 1 && process.env.NODE_ENV !== `production`) {
		console.warn(
			`We've found ${without.length} sources without a media property. They might be ignored by the browser, see: https://www.gatsbyjs.org/packages/gatsby-image/#art-directing-multiple-images`
		);
	}

	return [...withMedia, ...without];
}

/**
 * Checks if fluid or fixed are art-direction arrays.
 *
 * @param currentData  {{media?: string}[]}   The props to check for images.
 * @return {boolean}
 */
const hasArtDirectionSupport = (currentData) =>
	!!currentData &&
	Array.isArray(currentData) &&
	currentData.some((image) => typeof image.media !== `undefined`);

/**
 * Tries to detect if a media query matches the current viewport.
 * @property media   {{media?: string}}  A media query string.
 * @return {boolean}
 */
const matchesMedia = ({ media }) =>
	media ? isBrowser && !!window.matchMedia(media).matches : false;

/**
 * Returns the current src - Preferably with art-direction support.
 * @param currentData  {{media?: string}[], maxWidth?: Number, maxHeight?: Number}   The fluid or fixed image array.
 * @return {{src: string, media?: string, maxWidth?: Number, maxHeight?: Number}}
 */
const getCurrentSrcData = (currentData) => {
	if (isBrowser && hasArtDirectionSupport(currentData)) {
		// Do we have an image for the current Viewport?
		const foundMedia = currentData.findIndex(matchesMedia);
		if (foundMedia !== -1) {
			return currentData[foundMedia];
		}

		// No media matches, select first element without a media condition
		const noMedia = currentData.findIndex(
			(image) => typeof image.media === `undefined`
		);
		if (noMedia !== -1) {
			return currentData[noMedia];
		}
	}
	// Else return the first image.
	return currentData[0];
};
