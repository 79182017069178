import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import { buildFluidImageData } from "@imgix/gatsby-transform-url";

const FluidImage = ({
	className,
	url,
	sizes,
	width,
	height,
	ratio,
	desktop,
	...rest
}) => {
	const imageSource = [];

	const mobileImage = buildFluidImageData(
		`https://lending-people.imgix.net/${url}`,
		{
			ar: ratio ? ratio : width / height,
			auto: ["format", "compress"],
			crop: "entropy",
		},
		{
			sizes,
		}
	);

	imageSource.push(mobileImage);

	if (desktop?.url && (desktop.ratio || (desktop.width && desktop.height))) {
		const desktopImage = {
			...buildFluidImageData(
				`https://lending-people.imgix.net/${desktop.url}`,
				{
					ar: desktop.ratio ? desktop.ratio : desktop.width / desktop.height,
					auto: ["format", "compress"],
					crop: "entropy",
				},
				{
					sizes,
				}
			),
			media: "(min-width: 768px)",
		};
		imageSource.push(desktopImage);
	}

	return <Image className={className} fluid={imageSource} {...rest} />;
};

FluidImage.propTypes = {
	url: PropTypes.string,
	sizes: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	ratio: PropTypes.number,
	desktop: PropTypes.object,
};

FluidImage.defaultProps = {
	width: 1,
	height: 1,
};

export default FluidImage;
