import { css } from "@emotion/react";

export default function addFont(
	name,
	eot,
	woff,
	woff2,
	weight = 400,
	style = "normal"
) {
	return css`
		@font-face {
			font-family: "${name}";
			src: url("${eot}") format("eot"), url("${woff2}") format("woff2"),
				url("${woff}") format("woff");
			font-weight: ${weight};
			font-style: ${style};
			font-display: swap;
		}
	`;
}
