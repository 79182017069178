/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title, image }) {
	const globalSeo = useStaticQuery(
		graphql`
			query globalSeo {
				craft {
					globalSet {
						... on CraftCMS_seoPageMeta_GlobalSet {
							metaTitle
							metaDescription
							metaShareImage {
								filename
								url
							}
						}
					}
				}
			}
		`
	);

	const metaDescription =
		description || globalSeo?.craft?.globalSet.metaDescription;
	const metaImage =
		(image ? image[0]?.url : null) ||
		globalSeo?.craft?.globalSet?.metaShareImage?.[0]?.url;
	const defaultTitle = globalSeo?.craft?.globalSet?.metaTitle;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:share`,
					content: metaImage,
				},
				{
					property: `og:image`,
					content: metaImage,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: ``,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}>
			<script id="mcjs">{`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/771e70481cc85e41f4b8224bf/2a9f006d0b765c6a0251027c2.js");`}</script>
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
