import { css } from "@emotion/react";
import { pStandard } from "../styles/typography";
import addFont from "../styles/addFont";
import ModeratEot from "../fonts/Moderat-Regular.eot";
import ModeratWoff from "../fonts/Moderat-Regular.woff";
import ModeratWoff2 from "../fonts/Moderat-Regular.woff2";
import ModeratItalicEot from "../fonts/Moderat-Regular-Italic.eot";
import ModeratItalicWoff from "../fonts/Moderat-Regular-Italic.woff";
import ModeratItalicWoff2 from "../fonts/Moderat-Regular-Italic.woff2";
import ModeratMediumEot from "../fonts/Moderat-Medium.eot";
import ModeratMediumWoff from "../fonts/Moderat-Medium.woff";
import ModeratMediumWoff2 from "../fonts/Moderat-Medium.woff2";
import ModeratMediumItalicEot from "../fonts/Moderat-Medium-Italic.eot";
import ModeratMediumItalicWoff from "../fonts/Moderat-Medium-Italic.woff";
import ModeratMediumItalicWoff2 from "../fonts/Moderat-Medium-Italic.woff2";
import ModeratBoldEot from "../fonts/Moderat-Bold.eot";
import ModeratBoldWoff from "../fonts/Moderat-Bold.woff";
import ModeratBoldWoff2 from "../fonts/Moderat-Bold.woff2";
import ModeratBoldItalicEot from "../fonts/Moderat-Bold-Italic.eot";
import ModeratBoldItalicWoff from "../fonts/Moderat-Bold-Italic.woff";
import ModeratBoldItalicWoff2 from "../fonts/Moderat-Bold-Italic.woff2";
import ModeratLightEot from "../fonts/Moderat-Light.eot";
import ModeratLightWoff from "../fonts/Moderat-Light.woff";
import ModeratLightWoff2 from "../fonts/Moderat-Light.woff2";
import ModeratLightItalicEot from "../fonts/Moderat-Light-Italic.eot";
import ModeratLightItalicWoff from "../fonts/Moderat-Light-Italic.woff";
import ModeratLightItalicWoff2 from "../fonts/Moderat-Light-Italic.woff2";

const global = css`
	${addFont(
		"Moderat",
		ModeratLightEot,
		ModeratLightWoff,
		ModeratLightWoff2,
		300
	)}
	${addFont(
		"Moderat",
		ModeratLightItalicEot,
		ModeratLightItalicWoff,
		ModeratLightItalicWoff2,
		300,
		"italic"
	)}
	${addFont("Moderat", ModeratEot, ModeratWoff, ModeratWoff2)}
	${addFont(
		"Moderat",
		ModeratItalicEot,
		ModeratItalicWoff,
		ModeratItalicWoff2,
		400,
		"italic"
	)}
	${addFont(
		"Moderat",
		ModeratMediumEot,
		ModeratMediumWoff,
		ModeratMediumWoff2,
		500
	)}
	${addFont(
		"Moderat",
		ModeratMediumItalicEot,
		ModeratMediumItalicWoff,
		ModeratMediumItalicWoff2,
		500,
		"italic"
	)}
	${addFont("Moderat", ModeratBoldEot, ModeratBoldWoff, ModeratBoldWoff2, 600)}
	${addFont(
		"Moderat",
		ModeratBoldItalicEot,
		ModeratBoldItalicWoff,
		ModeratBoldItalicWoff2,
		600,
		"italic"
	)}
	

	body {
		font-family: "Moderat", "Helvetica", "Arial", sans-serif;
		${pStandard};
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	a {
		color: inherit;
		transition: color 0.2s ease;
	}
`;

export default global;
