import React from "react";
import styled from "@emotion/styled";
import Section, {
	BgEffectX,
	Para,
	ParaLarge,
	Heading1,
} from "./elements/Section";
import {
	container,
	gridColumnByMargin,
	spacingRestricted,
	screen,
	grid,
} from "../styles/mixins";
import Button from "./elements/Button";
import { button } from "../styles/typography";
import color from "../styles/colors";
import LinkTo from "./elements/LinkTo";
import PropTypes from "prop-types";

const ContainerEl = styled.div`
	${container()}
`;

const HeroGenericEl = styled.div`
	${gridColumnByMargin(2)};
	text-align: center;
`;

const BreadcrumbWrapper = styled.span`
	margin-right: 0.5em;
	margin-bottom: 0.5em;
	font-weight: 300;

	&::after {
		content: "/";
		margin-left: 0.5em;
		font-weight: 300;
	}

	&:last-of-type {
		margin-right: 0;
		&::after {
			content: "";
		}
	}
`;

const LinkToEl = styled(LinkTo)`
	font-weight: 500;

	&:hover {
		color: ${color("orange")};
	}
`;

const Title = styled(Heading1)`
	color: ${color("beige")};
`;

const TextWrapper = styled.div`
	@media ${screen("md")} {
		${grid(44)};
	}
`;

const ParaEl = styled(ParaLarge)`
	@media ${screen("md")} {
		${gridColumnByMargin(5, 44)};
	}
	@media ${screen("lg")} {
		${gridColumnByMargin(11, 44)};
	}
`;

const Buttons = styled.div`
	${button};
	margin-bottom: -0.2em;
	${spacingRestricted("margin-top", "s")}

	@media ${screen("md")} {
		${spacingRestricted("margin-top", "l")}
	}
`;

const ButtonWrapper = styled.span`
	margin-right: 0.5em;
	margin-bottom: 0.2em;
	display: inline-block;
`;

const HeroGeneric = ({
	className,
	title,
	text,
	breadcrumb,
	subLinks,
	colorTheme = "light",
	nextTheme = "dark",
}) => (
	<Section colorTheme={colorTheme}>
		<BgEffectX colorTheme={colorTheme} nextTheme={nextTheme} corner={true} />
		<ContainerEl>
			<HeroGenericEl className={className} colorTheme={colorTheme}>
				{breadcrumb?.length && (
					<Para>
						{breadcrumb?.map((link, i) =>
							link?.url ? (
								<BreadcrumbWrapper key={i}>
									<LinkToEl url={link.url}>{link.text}</LinkToEl>
								</BreadcrumbWrapper>
							) : (
								<BreadcrumbWrapper key={i}>
									<span key={i}>{link.text}</span>
								</BreadcrumbWrapper>
							)
						)}
					</Para>
				)}
				{title && <Title>{title}</Title>}
				{text && (
					<TextWrapper>
						<ParaEl>
							<span dangerouslySetInnerHTML={{ __html: text }}></span>
						</ParaEl>
					</TextWrapper>
				)}
				{subLinks?.length > 0 && (
					<Buttons>
						{subLinks.map(
							(link, i) =>
								link?.url && (
									<ButtonWrapper key={i}>
										<Button
											url={link.url}
											target={link.target}
											linkType={link.type}
											element={link?.element}
											external={link.type !== "entry"}
											type="sub-nav"
											colorTheme={colorTheme}
											activeClassName="active"
											partiallyActive={true}>
											{link.text}
										</Button>
									</ButtonWrapper>
								)
						)}
					</Buttons>
				)}
			</HeroGenericEl>
		</ContainerEl>
	</Section>
);

HeroGeneric.propTypes = {
	colorTheme: PropTypes.string,
	imageUrl: PropTypes.string,
};

HeroGeneric.defaultProps = {
	colorTheme: "light",
};

export default HeroGeneric;
