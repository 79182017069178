import React, { useState, useRef, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { navigation } from "../styles/typography";
import { screen } from "../styles/mixins";
import { ReactComponent as ChevronDown } from "../images/vectors/chevron-down.svg";

const Content = styled.div`
	height: ${(props) => (props.isOpen ? props.calculatedHeight : 0)};
	overflow: hidden;
	transition: height 0.3s cubic-bezier(0.09, 0.62, 0.3, 1);

	@media ${screen("md")} {
		height: auto;
	}
`;

const HeadingButton = styled.button`
	display: flex;
	width: 100%;
	text-align: left;
	align-items: center;

	svg {
		width: 4vw;
		max-width: 20px;
		transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "none")};
		transition: transform 0.2s;
	}

	&:focus {
		outline: none;
	}

	@media ${screen("md")} {
		cursor: default;
		pointer-events: none;
		display: none;

		svg {
			display: none;
		}
	}
`;

const Heading = styled.h2`
	${navigation};
	font-weight: bold;
	flex-grow: 1;
`;

const FooterAccordionItem = ({ heading, children, className }) => {
	const [open, setOpen] = useState(false);
	const [contentHeight, setContentHeight] = useState(0);

	const contentContainer = useRef();

	const updateScrollHeight = useCallback(() => {
		const contentScrollHeight = contentContainer.current.scrollHeight;
		setContentHeight(contentScrollHeight);
	}, []);

	useEffect(() => {
		updateScrollHeight();
		window.addEventListener("resize", updateScrollHeight);

		return () => {
			window.removeEventListener("resize", updateScrollHeight);
		};
	}, [updateScrollHeight]);

	const handleClick = (e) => {
		e.preventDefault();
		updateScrollHeight();
		setOpen(!open);

		window.dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: "expand_content",
			event_info: {
				event_category: "Content expansions",
				event_action: "Footer Navigation",
				event_label: heading,
			},
		});
	};

	return (
		<div className={className}>
			<HeadingButton onClick={handleClick} isOpen={open}>
				<Heading>{heading}</Heading>
				<ChevronDown />
			</HeadingButton>
			<Content isOpen={open} calculatedHeight={`${contentHeight}px`}>
				<div ref={contentContainer}>{children}</div>
			</Content>
		</div>
	);
};

FooterAccordionItem.propTypes = {
	colorTheme: PropTypes.string,
	heading: PropTypes.string,
};

FooterAccordionItem.defaultProps = {
	colorTheme: "light",
};

export default FooterAccordionItem;
