import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Heading2, Para, Display } from "./Section";
import Button from "./Button";
import { screen, screenMax, spacingRestricted } from "../../styles/mixins";

const HeadingEl = ({centered, ...props}) => {
	const Tag = centered ? Heading2 : Display;
	return (
		<Tag>{props.children}</Tag>
	)
}

const IntroEl = styled.div`
	text-align: ${(props) => props.centered ? "center" : "left"};
	${spacingRestricted("margin-bottom", "l")};
`;


const ButtonEl = styled(Button)`
	@media ${screenMax("md")} {
		width: 100%;
	}
	+ * {
		@media ${screenMax("md")} {
			margin-top: 20px;
		}
		@media ${screen("md")} {
			margin-left: 20px;
		}
	}
`

const ButtonWrap = styled.div`
	${spacingRestricted("margin-top", "ml")};
	@media ${screen("md")} {
		display: flex;
		flex-wrap: wrap;
		justify-content: ${props => props.centered ? "center" : "flex-start"};
	}
`



const Intro = ({ title, text, cta, secondaryCta, className, centered = true }) => {
	return (
		<IntroEl className={className} centered={centered}>
			{title && <HeadingEl centered={centered}>{title}</HeadingEl>}
			{text && <Para>{text}</Para>}
			<ButtonWrap centered={centered}>
				{cta?.url && (
					<ButtonEl
						url={cta.url}
						target={cta.target}
						linkType={cta.type}
						element={cta?.element}
						external={cta.type !== "entry"}
						data-event-name="select_cta"
						data-content-type="Go to page"
						data-content-destination={cta.url}
						data-content-text={cta.text}>
						{cta.text}
					</ButtonEl>
				)}
				{secondaryCta?.url && (
					<ButtonEl
						url={secondaryCta.url}
						type="secondary"
						target={secondaryCta.target}
						linkType={secondaryCta.type}
						element={secondaryCta?.element}
						external={secondaryCta.type !== "entry"}
						data-event-name="select_cta"
						data-content-type="Go to page"
						data-content-destination={secondaryCta.url}
						data-content-text={secondaryCta.text}
					>
						{secondaryCta.text}
					</ButtonEl>
				)}
			</ButtonWrap>
		</IntroEl>
)};

Intro.propTypes = {
	heading: PropTypes.string,
};

export default Intro;
