import { css } from "@emotion/react";
import { themeColors } from "../styles/colors";
import { transparentize } from "polished";
import color from "./colors";

export const breakpoints = {
	"": 0,
	"mn": 320,
	"sm": 480,
	"md": 768,
	"ml": 1024,
	"lg": 1280,
	"ms": 1680,
	"mx": 2200,
	"designM": 375,
	"designD": 1920,
};

const spacingFromSize = "designM";
const spacingToSize = "designD";

const spacingSizes = {
	"": {
		from: 0,
		to: 0,
	},
	"xxs": {
		from: 8,
		to: 12,
	},
	"xs": {
		from: 8,
		to: 16,
	},
	"s": {
		from: 16,
		to: 24,
	},
	"m": {
		from: 30,
		to: 32,
	},
	"ml": {
		from: 40,
		to: 40,
	},
	"l": {
		from: 48,
		to: 60,
	},
	"xl": {
		from: 50,
		to: 120,
	},
	"xxl": {
		from: 80,
		to: 180,
	},
	"xxxl": {
		from: 120,
		to: 240,
	},
};

export const screen = (breakpoint) =>
	`screen and (min-width: ${breakpoints[breakpoint]}px)`;

export const screenMax = (breakpoint) =>
	`screen and (max-width: ${breakpoints[breakpoint] - 1}px)`;

const gradient = (from, to, fromBreakpoint, toBreakpoint) => {
	const fromBreakpointInteger = breakpoints[fromBreakpoint];
	const toBreakpointInteger = breakpoints[toBreakpoint];

	const slope = (to - from) / (toBreakpointInteger - fromBreakpointInteger);
	const base = from - slope * fromBreakpointInteger;

	return { slope, base };
};

// Calculate fluid value interpolating between two values
export const fluid = (from, to, fromBreakpoint = "mn", toBreakpoint = "ms") => {
	const { slope, base } = gradient(from, to, fromBreakpoint, toBreakpoint);

	return `calc(${base}px + ${Math.round(10000 * slope) * 0.01}vw)`;
};

// Calculate fluid value that stays the same size in relation to the viewport (like percentage of viewport)
export const fluidPercentage = (value, breakpoint = "mx") => {
	const fromBreakpoint = "mn";
	const toBreakpoint = "mx";

	const fromValue =
		(value / breakpoints[breakpoint]) * breakpoints[fromBreakpoint];
	const toValue = (value / breakpoints[breakpoint]) * breakpoints[toBreakpoint];

	return fluid(fromValue, toValue, fromBreakpoint, toBreakpoint);
};

// Calculate fluid value but limit it to only between min and max breakpoints
export const fluidRestricted = (
	property,
	from,
	to,
	fromBreakpoint = "designM",
	toBreakpoint = "designD",
	minBreakpoint = "mn",
	maxBreakpoint = "mx"
) => {
	const { slope, base } = gradient(from, to, fromBreakpoint, toBreakpoint);
	const sizeAtMax = maxBreakpoint
		? slope * breakpoints[maxBreakpoint] + base
		: to;

	return css`
		${property}: ${from}px;

		@media ${screen(minBreakpoint || fromBreakpoint)} {
			${property}: ${fluid(from, to, fromBreakpoint, toBreakpoint)};
		}

		@media ${screen(maxBreakpoint || toBreakpoint)} {
			${property}: ${sizeAtMax}px;
		}
	`;
};

// Calculate fluid value but limit it to only between min and max breakpoints and make it stay same size in relation to the viewport (like percentage of viewport)
export const fluidPercentageRestricted = (
	property,
	value,
	breakpoint = "mx"
) => {
	const fromBreakpoint = "mn";
	const toBreakpoint = "mx";

	const fromValue =
		(value / breakpoints[breakpoint]) * breakpoints[fromBreakpoint];
	const toValue = (value / breakpoints[breakpoint]) * breakpoints[toBreakpoint];

	return css`
		${fluidRestricted(
			property,
			fromValue,
			toValue,
			fromBreakpoint,
			toBreakpoint
		)};
	`;
};

// Calculate spacing value (interpolated between spacingSize from and to)
export const spacing = (property, size = "m") => {
	const from = spacingSizes[size]?.from;
	const to = spacingSizes[size]?.to;

	return css`
		${property}: ${fluid(from, to, spacingFromSize, spacingToSize)};
	`;
};

// Calculate spacing value (interpolated) but limit it to only between min and max breakpoints
export const spacingRestricted = (property, size = "m", multi = 1) => {
	const from = spacingSizes[size]?.from * multi;
	const to = spacingSizes[size]?.to * multi;

	return fluidRestricted(property, from, to, spacingFromSize, spacingToSize);
};

// Grid element
export const grid = (cols = 48) => css`
	display: grid;
	grid-template-columns: repeat(${cols}, minmax(0, 1fr));
`;

// Grid child element with some sort of column margin
export const gridColumnByMargin = (marginByColumn = 2, parentCols = 48) => css`
	grid-column: ${parseInt(marginByColumn) + 1} / span
		${parseInt(parentCols) - 2 * parseInt(marginByColumn)};
`;

// Container element
export const container = () => css`
	margin-left: auto;
	margin-right: auto;
	max-width: ${breakpoints.designD}px;
	width: 100%;
	position: relative;
	${grid()};
`;

// Add padding to an element, horizontal padding can be based on grid columns
export const padding = (options = {}) => {
	const {
		verticalPadding = "l",
		horizontalPadding = 2,
		parentCols = 48,
	} = options;
	let horizontalPaddingCss = null;

	if (isNaN(horizontalPadding)) {
		horizontalPaddingCss = css`spacingRestricted("padding-left", ${horizontalPadding});
		spacingRestricted("padding-right", ${horizontalPadding});`;
	} else {
		horizontalPaddingCss = css`
			${gridColumnByMargin(horizontalPadding, parentCols)}
		`;
	}

	return css`
		${spacingRestricted("padding-top", verticalPadding)};
		${spacingRestricted("padding-bottom", verticalPadding)};
		${horizontalPaddingCss};
	`;
};

export const gridc = (start, width) => {
	return `grid-column: ${start} / span ${width};`;
};

// If number of siblings (including item) = count, then do something
export const withCount = (count) =>
	count === 1
		? `&:first-child:nth-last-child(1)`
		: `&:first-child:nth-last-child(${count}),
		&:first-child:nth-last-child(${count}) ~ &`;

export const inputStyles = (colorTheme = "dark") => {
	return `
		padding: 0.5em 1em;
		box-sizing: border-box;
		border: 1px solid ${transparentize(0.5, themeColors[colorTheme].fg)};
		border-radius: 4px;
		appearance: none;
		background: white;
		box-sizing: border-box;
	`;
};
