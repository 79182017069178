/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import reset from "../styles/reset";
import global from "../styles/global";
import { Global } from "@emotion/react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ colorTheme = "dark", children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<>
			<Global styles={[reset, global]} />
			<Header colorTheme={colorTheme} siteTitle={data.site.siteMetadata?.title || `Title`} />
			<main>{children}</main>
			<Footer colorTheme={colorTheme} />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
