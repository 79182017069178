import { transparentize } from "polished";

const color = (value) => {
	const colors = {
		"white": "#FFFFFF",
		"smoke": "#FBFBFB",
		"off-white": `#F6F6F8`,
		"purple": "#352037",
		"off-purple": "#502F53",
		"light-purple": transparentize(0.9, "#352037"),
		"green": "#0B453D",
		"mint": "#E0EFD6",
		"orange": "#FF7C66",
		"beige": "#FCF5D2",
		"grey": "#EDE8E8",
		"pink": "#FCE0E5",
		"blue": "#D7E9ED",
		"coal": "#EDE8E8",
		"light-grey": transparentize(0.9, "#EDE8E8"),
		"dust": transparentize(0.4, "#EDE8E8"),
	};
	return colors[value];
};

export default color;

export const themeColors = {
	light: {
		fg: color("purple"),
		bg: color("smoke"),
		accent1: color("orange"),
		accent2: color("beige"),
		accent3: color("beige"),
		accent4: color("grey"),
	},
	dark: {
		fg: color("white"),
		bg: color("purple"),
		accent1: color("orange"),
		accent2: transparentize(0.9, color("grey")),
		accent3: color("beige"),
		accent4: color("grey"),
	},
	purple: {
		fg: color("purple"),
		bg: color("off-purple"),
		accent1: color("beige"),
		accent2: color("beige"),
		accent3: color("beige"),
		accent4: color("grey"),
	},
	greenDark: {
		fg: color("white"),
		bg: color("green"),
		accent1: color("orange"),
		accent2: transparentize(0.9, color("grey")),
		accent3: color("beige"),
		accent4: color("mint"),
	},
	greenLight: {
		fg: color("green"),
		bg: color("smoke"),
		accent1: color("orange"),
		accent2: transparentize(0.9, color("grey")),
		accent3: color("beige"),
		accent4: color("mint"),
	},
	beige: {
		fg: color("purple"),
		bg: color("beige"),
		accent1: color("orange"),
		accent2: color("white"),
		accent3: color("white"),
	},
};

export const hexToRGB = (hex, alpha) => {
	hex = hex.replace("#", "");
	var r = parseInt(
		hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2),
		16
	);
	var g = parseInt(
		hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4),
		16
	);
	var b = parseInt(
		hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6),
		16
	);
	if (alpha) {
		return `rgba(${r}, ${g}, ${b}, ${alpha})`;
	} else {
		return `rgb(${r}, ${g}, ${b})`;
	}
};
